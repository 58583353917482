import {defineAsyncComponent} from 'vue';
import Column from 'primevue/column';

export default function registerGlobalComponents(appVue) {
    appVue.component(
        'font-awesome-icon',
        defineAsyncComponent(() => import('@fortawesome/vue-fontawesome').then((module) => module.FontAwesomeIcon))
    );
    appVue.component(
        'font-awesome-layers-text',
        defineAsyncComponent(() =>
            import('@fortawesome/vue-fontawesome').then((module) => module.FontAwesomeLayersText)
        )
    );
    appVue.component(
        'font-awesome-layers',
        defineAsyncComponent(() => import('@fortawesome/vue-fontawesome').then((module) => module.FontAwesomeLayers))
    );

    appVue.component(
        'DataTable',
        defineAsyncComponent(() => import('primevue/datatable'))
    );

    // En asyncrhone column ne semble pas fonctionner
    appVue.component('Column', Column);

    appVue.component(
        'InputText',
        defineAsyncComponent(() => import('primevue/inputtext'))
    );
    appVue.component(
        'InputNumber',
        defineAsyncComponent(() => import('primevue/inputnumber'))
    );
    appVue.component(
        'Checkbox',
        defineAsyncComponent(() => import('primevue/checkbox'))
    );
    appVue.component(
        'SelectButton',
        defineAsyncComponent(() => import('primevue/selectbutton'))
    );
    appVue.component(
        'Password',
        defineAsyncComponent(() => import('primevue/password'))
    );
    appVue.component(
        'ProgressSpinner',
        defineAsyncComponent(() => import('primevue/progressspinner'))
    );
    appVue.component(
        'Message',
        defineAsyncComponent(() => import('primevue/message'))
    );
    appVue.component(
        'Dialog',
        defineAsyncComponent(() => import('primevue/dialog'))
    );
    appVue.component(
        'Steps',
        defineAsyncComponent(() => import('primevue/steps'))
    );
    appVue.component(
        'Calendar',
        defineAsyncComponent(() => import('primevue/calendar'))
    );
    appVue.component(
        'TieredMenu',
        defineAsyncComponent(() => import('primevue/tieredmenu'))
    );

    appVue.component(
        'Dropdown',
        defineAsyncComponent(() => import('primevue/dropdown'))
    );
    appVue.component(
        'OverlayPanel',
        defineAsyncComponent(() => import('primevue/overlaypanel'))
    );
    appVue.component(
        'Panel',
        defineAsyncComponent(() => import('primevue/panel'))
    );
    appVue.component(
        'Textarea',
        defineAsyncComponent(() => import('primevue/textarea'))
    );
}
