import {canSeeRoute, currentUserHasRole, isCurrentCompanyDisabled, isAllowedTo} from '@utils';
import {AERIAL, BROKER, WHOLESALER, CELLINKS, CELLINKS_READER} from '@utils/roles';
export default {
    mixins: [],
    setup() {
        return {};
    },
    mounted() {},
    data() {
        return {AERIAL, BROKER, WHOLESALER, CELLINKS, CELLINKS_READER, applicationEnvironment: process.env.VUE_APP_ENVIRONMENT};
    },
    computed: {
        isAerial() {
            return currentUserHasRole(AERIAL);
        },
        isWholesaler() {
            return currentUserHasRole(WHOLESALER);
        },
        isCellinks() {
            return currentUserHasRole(CELLINKS);
        },
        isCellinksReader() {
            return currentUserHasRole(CELLINKS_READER);
        },
        isProd() {
            return this.applicationEnvironment === 'prod';
        },
    },

    methods: {
        canSeeRoute,
        currentUserHasRole,
        isCurrentCompanyDisabled,
        isAllowedTo,
    },
};
