<script>


/* MIXINS */
import messageMixin from "@mixins/messageMixin";
import loaderMixin from "@mixins/loaderMixin";
export default {
  components: {},
  mixins: [messageMixin, loaderMixin],
  data() {
    return {
      displayTab: {},
    }
  },
  props: {
    title: {type: String, required: true},
    tabs: {
      type: Array,
      required: true,
    },
    firstTab: {type: String, default: undefined}
  },
  mounted() {
    setTimeout(() => {
      if (this.firstTab) {
        for (let t of this.tabs) {
          if (t.id === this.firstTab) {
            this.displayTab = t;
          }
        }
      } else {
        this.displayTab = this.tabs[0];
      }
    }, 50); //TODO: supprimer ce timeout nécessaire pour l'onglet Bibliohtèque et outils
  },
  methods: {
    display(tab) {
      this.displayTab = tab;
    },
    
  },
  computed: {
    
  }
}
</script>
<template>
  <div class="d-flex flex-column ak-tab-view">
      <ul
          class="nav nav-tabs mb-2 tab-list  d-flex flex-wrap"
          role="tablist"
      >
        <template
            v-for="tab of tabs"
            :key="tab?.id"
        >
          <li
              v-if="!tab.hidden"
              :class="{'active':this.displayTab?.id === tab?.id}" class="nav-item flex align-items-center flex-grow-1 d-flex justify-content-center p-ripple"  v-ripple
              @click="display(tab)"
          >
          <font-awesome-icon v-if="null != tab.icon" class="mr-1" :icon="tab.icon" />
            {{ tab.name }}
          </li>
        </template>
      </ul>
    
      <template v-for="tab in tabs" :key="tab?.id">
        <keep-alive>
          <slot :name="tab?.id"  v-if="displayTab?.id==tab?.id"/>
        </keep-alive>
    </template>
    
  </div>
</template>
Réduire