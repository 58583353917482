<script>
import FileUpload from 'primevue/fileupload';
import AkLabel from "@components/general/AkLabel";

export default {
  components: {FileUpload, AkLabel},
  inject: ['pSubmitted', 'pDisabled'],
  props: {
    modelValue: {},
    className: {
      type: String,
      required: false,
      default: "col-md-4"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    validator: {
      type: Object,
      required: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: undefined
    },
      label: {
      type: String,
      required: false,
    },
  },
  methods: {
    selectFiles(event) {
      this.currentValue = event.files;
    },
    removeFile(event) {
      this.currentValue = event.files;
    },
    clearFiles() {
      this.currentValue = [];
    },
    clear() {
      this.$refs.uploader.clear();
    },

  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue;
      }, set: function (val) {
        this.$emit('update:modelValue', val);
      }
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
    isRequired() {
      return this.validator !== undefined && this.validator.required !== undefined;
    },
  }
}
</script>

<template>
  <div :class="this.className">
    <ak-label :required="isRequired" v-if="label">{{ this.label }}</ak-label>
    <FileUpload
        ref="uploader"
        name="uploader"
        :chooseLabel="$t('choose_file')"
        :cancelLabel="$t('cancel_file')"
        :multiple="multiple"
        :disabled="isDisabled"
        :showUploadButton=false
        :customUpload="true"
        @select="selectFiles"
        @remove="removeFile"
        @clear=clearFiles
    >
      <template #empty>
        <p>{{ $t('drag_files') }}</p>
      </template>
    </FileUpload>
  </div>
</template>