import {createStore} from 'vuex';
import TokenService from '@services/tokenService';
import route from '@router';
import jwt_decode from 'jwt-decode';
import PendingQuotes from '@store/newQuoteStore.js';
import ProductStore from '@store/productStore.js';
import productService from '@services/productService';

export default createStore({
    state: {
        currentUser: getSavedState('auth.currentUser'),
        userInformation: getSavedState('userInformation'),
        userProducts: getSavedState('userProducts'),
        base64Logo: null,
        refreshPromise: null,
        srvAppVersion: null,
    },
    mutations: {
        SET_CURRENT_USER(state, newValue) {
            state.currentUser = newValue;
            saveState('auth.currentUser', newValue);
        },

        SET_USER_INFO(state, newValue) {
            if (newValue && newValue.token) {
                const decoded = jwt_decode(newValue.token);
                const value = {
                    username: decoded.username,
                    email: decoded.email,
                    authorities: decoded.authorities,
                    userIdentity: decoded.userIdentity,
                    brokerId: decoded.brokerId,
                    userId: decoded.userId,
                    companyName: decoded.companyName,
                    companyId: decoded.companyId,
                    disabled: decoded.disabled,
                    companyAccesses: decoded.companyAccesses,
                    refreshToken: newValue.refreshToken,
                    isChangePassword: decoded.isChangePassword,
                    expireAt: decoded.exp,
                };

                window.localStorage.setItem('refreshToken', newValue.refreshToken);
                state.userInformation = value;
                saveState('userInformation', value);
            } else {
                state.userInformation = null;
                saveState('userInformation', null);
            }
        },
        SET_SRV_APP_VERSION(state, newValue) {
            state.srvAppVersion = newValue;
        },
        SET_PRODUCT_USER(state, newValue) {
            state.userProducts = newValue;
            saveState('userProducts', newValue);
        },
    },
    getters: {
        // Whether the user is currently logged in.
        loggedIn(state) {
            return !!state.currentUser;
        },
        currentTokenExpired(state) {
            return state.userInformation.expireAt < new Date().getTime();
        },
        currentUserAuthorities(state) {
            return state?.userInformation?.authorities;
        },
        currentUserProducts(state) {
            return state?.userProducts;
        },
        refreshPromise(state) {
            return state.refreshPromise;
        },
        currentCompanyAccess(state) {
            return state?.userInformation?.companyAccesses;
        },
        isUserChangePassword(state) {
            return state.userInformation.isChangePassword;
        },
    },
    actions: {
        // This is automatically run in `src/state/store.js` when the app
        // starts, along with any other actions named `init` in other modules.
        init({dispatch}) {
            dispatch('validate');
        },

        // Logs in the current user.
        async logIn({commit, dispatch, getters}, {username, password} = {}) {
            if (getters.loggedIn) return dispatch('validate');
            var user = {login: username, password: password};
            let promise = [];
            let logProm = await TokenService.login(user).then(async (data) => {
                const user = data;
                commit('SET_CURRENT_USER', user);
                commit('SET_USER_INFO', user);
                let productProm = await productService.list().then((data) => {
                    commit('SET_PRODUCT_USER', data);
                });
                promise.push(productProm);
                return user;
            });
            promise.push(logProm);
            return promise;
        },

        // Logs in the current user.
        setAPPVersion({commit}, appVersion) {
            commit('SET_SRV_APP_VERSION', appVersion);
        },

        refresh({commit, state}) {
            if (null != state.refreshPromise) return state.refreshPromise;

            var refreshToken = {
                refreshToken: window.localStorage.getItem('refreshToken'),
                login: state.currentUser.login,
            };
            state.refreshPromise = TokenService.refresh(refreshToken)
                .then((data) => {
                    state.refreshPromise = null;
                    const user = data;
                    commit('SET_CURRENT_USER', user);
                    commit('SET_USER_INFO', user);

                    return user;
                })
                .finally(() => {
                    state.refreshPromise = null;
                });

            return state.refreshPromise;
        },

        // Logs out the current user.
        logOut({commit}) {
            route.push({name: 'login'});
            commit('SET_CURRENT_USER', null);
            commit('SET_PRODUCT_USER', null);
            commit('SET_USER_INFO', null);
        },

        logOutExpired({commit}) {
            route.push({name: 'login', query: {redirectFrom: route.currentRoute.value.fullPath}});
            commit('SET_CURRENT_USER', null);
            commit('SET_PRODUCT_USER', null);
            commit('SET_USER_INFO', null);
        },

        // Validates the current user's token and refreshes it
        // with new data from the API.
        validate({state, commit}) {
            if (!state.currentUser) return Promise.resolve(null);
            if (null == state.userProducts) {
                productService.list().then((data) => {
                    commit('SET_PRODUCT_USER', data);
                });
            }
            return state.currentUser;
        },

        toggleIsChangePassword({state}) {
            state.userInformation.isChangePassword = !state.userInformation.isChangePassword;
            saveState('userInformation', state.userInformation);
        },
    },
    modules: {pendingQuotes: PendingQuotes, productStore: ProductStore},
});

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state));
}
