export default {
    methods: {
        showLoader(ref, canCancel = false, backgroundColor = "#FFF", color = "#064CDB") {
            return this.$loading.show({
                container: ref,
                canCancel: canCancel,
                backgroundColor: backgroundColor,
                color: color,
                isFullPage: false
            });
        },
    }
}