<script>
import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@utils/validatorsUtils';
import AkInputText from '@components/input/AkInputText.vue';
import AkCheckboxButton from '@components/input/AkCheckboxButton.vue';
import AkFileUpload from '@components/input/AkFileUpload.vue';
import AkTabView2 from '@components/layout/AkTab.vue';
import AkFormView from '@components/layout/AkFormView.vue';
export default {
    components: {
        // AkTabView2,
        AkInputText,
        AkCheckboxButton,
        AkFileUpload,
        AkFormView,
    },
    mixins: [],
    setup() {
        return {v$: useVuelidate()};
    },
    metaInfo() {
        return {
            title: 'Sandbox',
        };
    },
    validations() {
        return {
            item: {
                text: {
                    required: requiredIf(() => this.item.booleen === true),
                },
            },
        };
    },
    data() {
        return {
            modalVisible: true,
            item: {
                text: '',
                booleen: false,
            },
            yesNo: [
                {name: 'Oui', code: true},
                {name: 'Non', code: false},
            ],
            title: 'Titre',
            pSubmitted: true,
            tabsList: [
                {name: 'Juridique / Contractuel', id: 'tab1'},
                {name: "Outils d'aide à la vente", id: 'tab2'},
                {name: 'Juridique / Contractuel', id: 'tab3'},
                {name: "Outils d'aide à la vente", id: 'tab4'},
                {name: 'Juridique / Contractuel', id: 'tab5'},
                {name: "Outils d'aide à la vente", id: 'tab6'},
            ],
        };
    },
    mounted() {
        this.v$.$touch();
        if (this.v$.$error) {
            console.log(this.v$.$errors);
            return;
        }
    },
    methods: {
        onEvent() {
            console.log('onevent');
        },
    },
    computed: {
        tabs() {
            let tabs = [];
            tabs.push({
                name: 'onglet 1',
                id: 'BROKER',
                icon: 'user-tie',
                subTabs: [
                    {name: 'sous onglet 1', id: 'SUB_BROKER', icon: 'user-tie'},
                    {name: 'sous onglet 2', id: 'SUB_BROKER3', icon: 'user-tie'},
                    {name: 'sous onglet 3', id: 'SUB_BROKER4', icon: 'user-tie'},
                ],
            });
            tabs.push({name: 'onglet 2', id: 'BROKER_LIST', icon: 'user-tie'});

            return tabs;
        },
        displayTabs() {
            return this.tabs.length > 1;
        },
    },
    watch: {},
};
</script>
<template>
    <h1>sandbox</h1>
    <div class="app-wrap">
        <ak-form-view :ref="ref" v-bind:title="title" :tooltip="tooltipValue" :tabs="tabs">
            <template v-slot:tabs v-if="displayTabs">
                <div class="tab nav-border-bottom">
                    <ul class="nav nav-tabs" role="tablist">
                        <template v-for="tab of this.tabs" :key="tab">
                            <li class="nav-item pointer" @click="displayTab(tab.tabShortname)">
                                <span class="show nav-link" :class="{active: tab.tabShortname === activeTab}">
                                    <font-awesome-icon :icon="['fas', tab.icon]" fixed-width />
                                    {{ tab.name }}
                                </span>
                            </li>
                        </template>
                    </ul>
                </div>
            </template>
            <template v-slot:form> plop </template>
            <template #BROKER__SUB_BROKER>
              <AkCheckboxButton
                    label="Bouton"
                    optionValue="code"
                    :options="yesNo"
                    v-model="item.booleen"
                    :multiple="false" />
                <AkInputText label="Texte" v-model="item.text" :validator="v$.item.text" :submitted="true" />
                <AkFileUpload
                    label="Fichier"
                    v-model="item.file"
                    :validator="v$.item.file"
                    :submitted="true"
                    :multiple="false" />
            </template>
            <template #BROKER="slotProps">
              {{ slotProps.subTab }}
                <AkCheckboxButton
                    label="Bouton"
                    optionValue="code"
                    :options="yesNo"
                    v-model="item.booleen"
                    :multiple="false" />
                <AkInputText label="Texte" v-model="item.text" :validator="v$.item.text" :submitted="true" />
                <AkFileUpload
                    label="Fichier"
                    v-model="item.file"
                    :validator="v$.item.file"
                    :submitted="true"
                    :multiple="false" />
            </template>
            <template #BROKER_LIST> contenu tab 2 </template>
            <template v-slot:action> </template>
        </ak-form-view>
        <!-- 
    <div style="width:100%">
    
    <ak-tab-view-2 :tabs="tabsList">
      <template #tab1>
        <AkCheckboxButton label="Bouton" optionValue="code" :options="yesNo" v-model="item.booleen" :multiple="false"/>
    <AkInputText label="Texte" v-model="item.text" :validator="v$.item.text" :submitted="true"/>
    <AkFileUpload label="Fichier" v-model="item.file" :validator="v$.item.file" :submitted="true" :multiple="false"/>
      </template>
      <template #tab2>
        contenu tab 2
      </template>
    </ak-tab-view-2>
  </div> -->
    </div>
</template>
