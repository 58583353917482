import {createI18n} from 'vue-i18n';
import {fr} from '@lang/fr.js';
import {en} from '@lang/en.js';

export const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: 'fr',
    fallbackWarn: false,
    missingWarn: false,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    messages: {
        fr: fr,
        en: en,
    },
});

export default i18n;
