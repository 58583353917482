export default {
    namespaced: true,
    state: {
        pendingQuote: getSavedState('pendingQuote'),
    },
    mutations: {
        SET_PENDING_QUOTES(state, newValue) {
            state.pendingQuote = newValue;
            saveState('pendingQuote', state.pendingQuote);
        },

        ADD_PENDING_QUOTE(state, newValue) {
            let keyId = newValue.pendingID;
            state.pendingQuote[keyId] = newValue;
            saveState('pendingQuote', state.pendingQuote);
        },
    },
    getters: {
        pendingQuotes(state) {
            return state.pendingQuote;
        },
    },
    actions: {
        // This is automatically run in `src/state/store.js` when the app
        // starts, along with any other actions named `init` in other modules.

        addPendingQuote({commit, getters}, {pendingQuote}) {
            const savedPendingQuote = null != getters.pendingQuotes ? getters.pendingQuotes : {};
            savedPendingQuote[pendingQuote.pendingID] = pendingQuote;
            commit('SET_PENDING_QUOTES', savedPendingQuote, {root: false});
        },
    },
    modules: {},
};

// ===
// Private helpers
// ===

function getSavedState(key) {
    return JSON.parse(window.sessionStorage.getItem(key));
}

function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state));
}
