<script>
import AkLabel from '@components/general/AkLabel';

export default {
    components: {AkLabel},
    inject: {
        pSubmitted: {from: 'pSubmitted', default: null},
        pDisabled: {from: 'pDisabled', default: null},
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {},
        placeholder: {
            type: String,
            required: false,
        },
        className: {
            type: String,
            required: false,
            default: 'col-md-6',
        },
        validator: {
            type: Object,
            required: false,
        },
        submitted: {
            type: Boolean,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: undefined,
        },
        unselectable: {
            type: Boolean,
            required: false,
            default: true,
        },
        returnType: {type: String, default: 'String'},
        options: {
            type: Array,
        },
        optionLabel: {
            type: String,
            default: 'name',
        },
        optionValue: {
            type: String,
        },
        optionDisabled: {
            type: String,
            default: 'disabled',
        },
        multiple: {
            type: Boolean,
            default: true,
        },
        addNullOpt: {
            type: Boolean,
            default: false,
        },
        nullLabel: {
            type: String,
            default: null,
        },
        onChange: {
            type: Function,
            required: false,
            default: undefined,
        },
        tooltip: {
            type: String,
            required:false,
        },
    },
    data() {
        return {
            id: null,
        };
    },
    mounted() {
        this.id = 'id_' + Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    },
    methods: {},
    computed: {
        currentValue: {
            get: function () {
                return this.modelValue;
                // if (this.modelValue) {
                //       if (this.returnType == "Object") {
                //           return this.modelValue[this.trackBy]
                //       } else {
                //           return this.modelValue;
                //       }
                //   } else {
                //       return this.multi ? [] : null;
                //   }
            },
            set: function (val) {
                this.$emit('update:modelValue', val);
            },
        },
        getPlaceholder() {
            return this.placeholder ? this.placeholder : this.label;
        },
        alreadySubmitted() {
            if (this.submitted !== undefined) return this.submitted;
            if (this.pSubmitted !== undefined) return this.pSubmitted;
            return false;
        },
        isDisabled() {
            if (this.disabled !== undefined) return this.disabled;
            if (this.pDisabled !== undefined) return this.pDisabled;
            return false;
        },
        isInvalid() {
            return this.validator !== undefined && this.validator.$invalid && this.alreadySubmitted;
        },
        isRequired() {
            return this.validator !== undefined && this.validator.required !== undefined;
        },
        isValidatorRequired() {
            return (
                this.validator !== undefined &&
                this.validator.required !== undefined &&
                this.validator.required.$invalid &&
                this.alreadySubmitted
            );
        },
        _options() {
            let tmpOpt = [...this.options];
            if (this.addNullOpt) {
                let nullValue = {};
                nullValue[this.optionValue] = null;
                nullValue[this.optionLabel] = this.nullLabel ? this.nullLabel : this.$t('anyValue');
                tmpOpt.push(nullValue);
            }
            return tmpOpt;
        },
    },
};
</script>

<template>
    <div :class="this.className" class="form-group ak-checkbox-button p-fluid">
        <ak-label :required="isRequired">{{ this.label }}<i v-if="null !=tooltip" class="fa fa-info-circle tooltip-centered" v-tooltip="tooltip"/></ak-label>
        <SelectButton
            v-model="currentValue"
            :options="_options"
            :disabled="disabled"
            :unselectable="unselectable"
            :optionValue="optionValue"
            :optionDisabled="optionDisabled"
            :multiple="multiple"
            :optionLabel="optionLabel"
            style="height: 39px"
            @change="onChange" />

        <!-- <Checkbox :id="id" v-model="currentValue" :binary="true" class="form-check-input"
              value="{{this.label}}" :disabled="this.disabled"/> -->

        <template v-if="validator && alreadySubmitted">
            <small v-for="error of validator.$errors" :key="error.$uid" class="p-error">
                {{ $t(error.$message) }}
            </small>
        </template>
    </div>
</template>
