export const financialReceiptNature = {
    PERCEPT: 'Perception',
    REFUND: 'Remboursement',
};

export const financialReceiptStatus = {
    PLANNED: 'Planifiée',
    PAYED: 'Payée',
    UNPAID: 'Impayée',
    MED: 'MED',
    EXPORTED: 'Exportée',
    CANCELED: 'Annulée',
};

export const financialReceiptType = {
    INITIAL: 'Initial',
    ECHEANCE: 'Echéance',
    RESILIATION: 'Résiliation',
    AVENANT: 'Avenant',
    RENEW: 'Terme / Renouvellement',
};
export const financialReceiptQualif = {
    RECOUVRABLE_LESS_THREE_MONTH: 'Recouvrable -3 mois',
    RECOUVRABLE_MORE_THREE_MONTH: 'Recouvrable +3 mois',
    NON_RECOUVRABLE: 'Non recouvrable',
};
