export default {
    data() {
        return {
            messages: [],
        }
    },
    methods: {
        error(content, closable = true) {
            this.messages.push({
                closable: closable, severity: "error", content: content
            })
        },
        success(content, closable = true) {
            this.messages.push({
                closable: closable, severity: "success", content: content
            })
        },
        resetMessages() {
            this.messages = []
        },
    }
}