import {helpers} from '@vuelidate/validators';
import * as validators from "@vuelidate/validators";

import { i18n } from "./i18n";

import IBAN from "iban";
import BIC from "bic";

function valueIsEmpty(value) {
    return value == null || value === '';
}

function valueIsNumber(value) {
    return !isNaN(value);
}

function nbDigit(value) {
    return value.replace(/\D/g, '').length;
}

export const _phoneNumber = function (value) {
    return (
        valueIsEmpty(value) ||
        (value.charAt(0) == 0 &&
            value.match(
                '^(?:(?:\\+|00)33[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})$'
            ))
    );
};

export const phoneNumber = helpers.withMessage('inputErrorCode.phoneLength', _phoneNumber);

export const _postalCode = function (value) {
    return valueIsEmpty(value) || (value.length == 5 && nbDigit(value) == 5 && valueIsNumber(value));
};

export const postalCode = helpers.withMessage('inputErrorCode.postalCodeLength', _postalCode);

export const _mail = function (value) {
    // eslint-disable-next-line no-control-regex
    const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
    return value.match(emailRegex);
};
export const mail = helpers.withMessage('inputErrorCode.mail', _mail);

export const _akYearDate = function (value) {
    return valueIsEmpty(value) || (value.length == 4 && nbDigit(value) == 4 && valueIsNumber(value));
};

export const akYearDate = helpers.withMessage('inputErrorCode.dateYear', _akYearDate);

export const _oriasNumber = function (value) {
    return valueIsEmpty(value) || (value.length == 8 && valueIsNumber(value));
};

export const oriasNumber = helpers.withMessage('inputErrorCode.oriasNumberLength', _oriasNumber);

export const _siretNumber = function (value) {
    return valueIsEmpty(value) || (value.length == 14 && valueIsNumber(value));
};

export const siretNumber = helpers.withMessage('inputErrorCode.siretNumberLength', _siretNumber);

export const _rcsNumber = function (value) {
    return valueIsEmpty(value) || (value.length == 9 && valueIsNumber(value));
};

export const rcsNumber = helpers.withMessage('inputErrorCode.rcsNumberLength', _rcsNumber);

const _sirenNumber = function (value) {
    return valueIsEmpty(value) || (value.length == 9 && valueIsNumber(value));
};

export const sirenNumber = helpers.withMessage('inputErrorCode.sirenNumberLength', _sirenNumber);

export const _AkAlpha = function (value) {
    //TODO faire une meilleure regex https://blog.smarchal.com/regex-et-unicode
    if (valueIsEmpty(value) || value.match("^[a-zA-Z 'ÀÁÂÃÄÅÇÑñÇçÈÉÊËÌÍÎÏÒÓÔÕÖØÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöøùúûüýÿ,-]+$"))
        return true;
    return false;
};

export const akAlpha = helpers.withMessage('inputErrorCode.alphanumeric', _AkAlpha);

export const _AkNumber = function (value) {
    if (valueIsEmpty(value) || value.match('^[0-9]+$')) return true;
    return false;
};

export const akNumber = helpers.withMessage('inputErrorCode.numeric', _AkNumber);

export const _bicNumber = function (value) {
    if('prod' !== process.env.VUE_APP_ENVIRONMENT){
        if (value=="BICBICBIC"){return true}
    }
    return BIC.isValid(value)
};

export const bicNumber = helpers.withMessage('inputErrorCode.bicNumberError', _bicNumber);

export const _ibanNumber = function (value) {
    if('prod' !== process.env.VUE_APP_ENVIRONMENT){
        if (value=="FR11111111111111111111111111"){return true}
    }
    const ibanRegex = "[A-Z0-9]*" ;
    return IBAN.isValid(value) && (value.match(ibanRegex) == value);
};

export const ibanNumber = helpers.withMessage('inputErrorCode.ibanNumberError', _ibanNumber);

export const _garageCA = function (value) {
    return valueIsEmpty(value) || value <= 500000;
};

export const garageCA = helpers.withMessage('inputErrorCode.caSize', _garageCA);

export const _garageSuperficy = function (value) {
    return valueIsEmpty(value) || value <= 350;
};

export const garageSuperficy = helpers.withMessage('inputErrorCode.superficySize', _garageSuperficy);

const _crm = function (value) {
    return valueIsEmpty(value) || (value >= 50 && value <= 350);
};

export const crm = helpers.withMessage('inputErrorCode.crm', _crm);

const _akFreeInputLength = function (value) {
    return valueIsEmpty(value) || value.length < 1000;
};

export const akFreeInputLength = helpers.withMessage('inputErrorCode.akFreeInputLength', _akFreeInputLength);

const _rateNumber = function (value) {
    return valueIsEmpty(value) || (value >= 0 && value <= 100);
};

export const rateNumber = helpers.withMessage('inputErrorCode.rateNumber', _rateNumber);

const _fileRequired = function (value) {
    return value !== null;
}

export const requiredFile = helpers.withMessage('inputErrorCode.fileRequired', _fileRequired)

const _rowSelection = function (value = null) {
    return value !== null || value !== true || value !== undefined || value !== '';
}

export const requiredRowSelection = helpers.withMessage('inputErrorCode.rowRequired', _rowSelection)

const withI18nMessage = validators.createI18nMessage({ t: i18n.global.t.bind(i18n) });
export const required = withI18nMessage(validators.required);
export const requiredIf = (prop) => withI18nMessage(validators.requiredIf(prop));
export const requiredUnless = (prop) => withI18nMessage(validators.requiredUnless(prop));
export const minLength = (min) => withI18nMessage(validators.minLength(min));
export const maxLength = (max) => withI18nMessage(validators.maxLength(max));
export const minValue = (min) => withI18nMessage(validators.minValue(min));
export const maxValue = (max) => withI18nMessage(validators.maxValue(max));
export const between = (min, max) => withI18nMessage(validators.between(min, max));
export const alpha = withI18nMessage(validators.alpha);
export const alphaNum = withI18nMessage(validators.alphaNum);
export const numeric = withI18nMessage(validators.numeric);
export const integer = withI18nMessage(validators.integer);
export const decimal = withI18nMessage(validators.decimal);
export const email = withI18nMessage(validators.email);
export const ipAddress = withI18nMessage(validators.ipAddress);
export const macAddress = (separator) => withI18nMessage(validators.macAddress(separator));
export const sameAs = (equalTo) => withI18nMessage(validators.sameAs(equalTo));
export const url = withI18nMessage(validators.url);
export const or = () => withI18nMessage(validators.or());
export const and = () => withI18nMessage(validators.and());
export const not = (validator) => withI18nMessage(validators.not(validator));