import routes from './routes';
import {createRouter, createWebHistory} from 'vue-router';
import store from '@store';

const router = createRouter({
    routes,

    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
    const currentCompanyIsNotDisabled = routeTo.matched.some((route) => route.meta.currentCompanyIsNotDisabled);

    if (currentCompanyIsNotDisabled) {
        if (store?.state?.userInformation?.disabled) {
            redirectToUnauthorized();
        }
    }

    // If auth isn't required for the route, just continue.
    if (!authRequired) return next();

    // If auth is required and the user is logged in...
    if (store.getters['loggedIn']) {
        if (null != routeTo?.meta?.productAccessRequired) {
            let companyAccesses = store.getters['currentCompanyAccess'];
            let isAllowed = false;
            routeTo?.meta?.productAccessRequired.forEach((expectedProductId) => {
                if (companyAccesses.includes(expectedProductId)) isAllowed = true;
            });
            if (!isAllowed) redirectToUnauthorized();
        }

        if (null != routeTo?.meta?.roles) {
            let currentUserRole = store.getters['currentUserAuthorities'];
            let isAllowed = false;
            routeTo?.meta?.roles.forEach((expectedRole) => {
                if (currentUserRole.includes(expectedRole)) isAllowed = true;
            });
            if (!isAllowed) redirectToUnauthorized();
        }

        // Validate the local user token...
        return store.dispatch('validate').then((validUser) => {
            // Then continue if the token still represents a valid user,
            // otherwise redirect to login.
            validUser ? next() : redirectToLogin();
        });
    }
    redirectToLogin();

    function redirectToLogin() {
        // Pass the original route to the login component
        next({name: 'login', query: {redirectFrom: routeTo.fullPath}});
    }
    function redirectToUnauthorized() {
        next({name: 'unauthorized'});
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // Complete the redirect.
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});
export default router;
