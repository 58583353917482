import Api from '@/services/api';
import {createFormData, removeEmptyParams} from '@utils';
import {downloadUtils} from '@utils/downloadUtils';

export default {
    list(filter) {
        return Api()
            .get('/products', {params: removeEmptyParams(filter)})
            .then((res) => {
                res.data.forEach((e) => {
                    this.formatRes(e);
                });
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                return res.data;
            });
    },
    get(id) {
        return Api()
            .get('/products/' + id)
            .then((res) => this.formatRes(res.data));
    },
    getByProductCode(productCode) {
        return Api()
            .get('/products/code/' + productCode)
            .then((res) => this.formatRes(res.data));
    },

    create(item, files) {
        let formData = createFormData(item, files);
        return Api()
            .post('/products', formData)
            .then((res) => this.formatRes(res.data));
    },
    update(owner) {
        return Api()
            .put('/products/' + owner.id, owner)
            .then((res) => this.formatRes(res.data));
    },
    delete(owner) {
        return Api()
            .delete('/products/' + owner.id)
            .then(() => owner);
    },
    simulate(item) {
        return Api()
            .get('/products/' + item.productId + '/prices', {params: removeEmptyParams(item)})
            .then((res) => this.formatRes(res.data));
    },
    getActiveProductDocuments(productId) {
        return Api()
            .get('/products/' + productId + '/documents')
            .then((res) => this.formatRes(res.data));
    },

    formatRes(e) {
        if (e.expirationDate) e.expirationDate = new Date(e.expirationDate);
        if (e.marketingStart) e.marketingStart = new Date(e.marketingStart);
        if (e.marketingEnd) e.marketingEnd = new Date(e.marketingEnd);
        return e;
    },

    downloadFile(productId, fileType, fileHash, fileName, preview) {
        return downloadUtils('/products/' + productId + '/documents/' + fileType, fileHash, fileName, preview);
    },
    uploadDocument(productId, type, files) {
        let formData = new FormData();
        if (files instanceof Array) {
            Array.from(files).forEach((f) => {
                formData.append('file', f);
            });
        } else {
            formData.append('file', files);
        }
        return Api().post('/products/' + productId + '/documents/' + type, formData);
    },
    deleteDocument(productId, type, fileHash) {
        let params = {
            md5: fileHash,
        };
        return Api().delete('/products/' + productId + '/documents/' + type, {params: params});
    },

    uploadVehicleListFile(vehiculeFile, vehiculeCodificationFile, equiteId) {
        let formData = new FormData();
        formData.append('vehiculeFile', vehiculeFile);
        formData.append('vehiculeCodificationFile', vehiculeCodificationFile);

        return Api().post('/products/' + equiteId + '/vehicles', formData);
    },

    vehicleListUploading() {
        return Api().get('/products/vehicles/uploading');
    },
};
