import store from '@store';
import {AERIAL, BROKER, WHOLESALER, CELLINKS, CELLINKS_READER} from '@utils/roles';

const negoAutoQuoteRoutes = {
    path: 'negociant-auto/',
    name: 'negociantAutoQuoteCreationWrap',
    component: () => import('@views/quote/QuoteCreationWrap.vue'),
    meta: {
        authRequired: true,
        isCreation: true,
        currentCompanyIsNotDisabled: true,
        roles: [BROKER],
        product: 'NA',
        productAccessRequired: [1],
    },
    children: [
        {
            path: ':pendingQuoteId/customer-select',
            name: 'quoteNegoAutoCustomerSelector',
            component: () => import('@views/quote/QuoteCustomerSelector.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                nextStep: 'negoAutoquoteCustomerForm',
            },
        },
        {
            path: ':pendingQuoteId/newCustomer',
            name: 'negoAutoquoteCustomerForm',
            component: () => import('@views/quote/negoAuto/NewQuoteCustomerCreation.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/garage-select',
            name: 'negoAutoQuoteGarageSelector',
            component: () => import('@views/quote/negoAuto/QuoteGarageSelector.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/newGarage',
            name: 'negoAutoGarage',
            component: () => import('@views/quote/negoAuto/NewQuoteGarageCreation.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/warranty',
            name: 'negoAutoQuoteWarrantyForm',
            component: () => import('@views/quote/negoAuto/NewQuoteWarrantyForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/document-upload',
            name: 'negoAutoQuoteUploadDocument',
            component: () => import('@views/quote/negoAuto/NewQuoteUploadDocument.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/quote-result',
            name: 'negoAutoQuoteResult',
            component: () => import('@views/quote/NewQuoteResult.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
    ],
};

const equiteQuoteRoutes = {
    path: 'equite/',
    name: 'equiteQuoteCreationWrap',
    component: () => import('@views/quote/equite/EquiteQuoteCreationWrap.vue'),
    meta: {
        authRequired: true,
        isCreation: true,
        currentCompanyIsNotDisabled: true,
        roles: [BROKER],
        product: 'EQ',
        productAccessRequired: [2],
    },
    children: [
        {
            path: ':pendingQuoteId/customer-select',
            name: 'quoteEquiteCustomerSelector',
            component: () => import('@views/quote/QuoteCustomerSelector.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
                nextStep: 'newQuoteEquiteForm',
            },
        },
        {
            path: ':pendingQuoteId/main',
            name: 'newQuoteEquiteForm',
            component: () => import('@views/quote/equite/NewQuoteEquite.vue'),
            meta: {
                isCreation: true,
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/driver/secondary',
            name: 'SecondaryDriverForm',
            component: () => import('@views/quote/equite/DriverForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
                mainDriver: false,
            },
        },
        {
            path: ':pendingQuoteId/driver/main',
            name: 'MainDriverForm',
            component: () => import('@views/quote/equite/DriverForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
                mainDriver: true,
            },
        },
        {
            path: ':pendingQuoteId/options',
            name: 'ContractOptionsForm',
            component: () => import('@views/quote/equite/ContractOptionsForm.vue'),
            meta: {
                isCreation: true,
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/vehicle',
            name: 'VehicleForm',
            component: () => import('@views/quote/equite/VehicleForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
        {
            path: ':pendingQuoteId/antecedents/main',
            name: 'MainDriverAntecedentsForm',
            component: () => import('@views/quote/equite/DriverAntecedentsForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
                mainDriver: true,
            },
        },
        {
            path: ':pendingQuoteId/antecedents/secondary',
            name: 'SecondaryDriverAntecedentsForm',
            component: () => import('@views/quote/equite/DriverAntecedentsForm.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
                mainDriver: false,
            },
        },
        {
            path: ':pendingQuoteId/quote-result',
            name: 'EquiteQuoteResult',
            component: () => import('@views/quote/NewQuoteResult.vue'),
            meta: {
                currentCompanyIsNotDisabled: true,
                roles: [BROKER],
            },
        },
    ],
};

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@views/Login.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/cbd',
        name: 'cbd',
        component: () => import('@views/public/Cbd.vue'), //<-- La page que tu vas créer
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    {
        path: '/contract/:uid/paiement',
        name: 'contractExternalPaiement',
        component: () => import('@views/public/ContractExternalPaiement.vue'),
    },
    {
        path: '/contract/:uid/signature',
        name: 'contractExternalSignature',
        component: () => import('@views/public/ContractExternalSignature.vue'),
    },
    {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: () => import('@views/PwdForget.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: '/resetPwd/:resetToken',
        name: 'resetPwd',
        component: () => import('@views/PwdReset.vue'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['loggedIn']) {
                    // Redirect to the home page instead
                    next({name: 'home'});
                } else {
                    // Continue to the reset password page
                    next();
                }
            },
        },
    },
    {
        path: '/resetTmpPwd',
        name: 'resetTmpPwd',
        component: () => import('@views/PwdTmpReset.vue'),
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['loggedIn'] && !store.getters['isUserChangePassword']) {
                    // Redirect to the home page instead
                    next({name: 'home'});
                } else {
                    // Continue to the reset password page
                    next();
                }
            },
        },
    },
    {
        path: '/contract/:uid/regularization',
        name: 'contractExternalregularization',
        component: () => import('@views/public/ContractExternalRegularization.vue'),
    },
    {
        path: '/',
        component: () => import('@layouts/Main.vue'),
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@views/Home.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'souscripteurs',
                name: 'souscripteurs',
                component: () => import('@views/subscriber/SubscriberList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, WHOLESALER],
                },
            },
            {
                path: 'souscripteurs/create',
                name: 'souscripteursCreate',
                component: () => import('@views/subscriber/SubscriberForm.vue'),
                meta: {
                    authRequired: true,
                    isCreation: true,
                    currentCompanyIsNotDisabled: true,
                    roles: [AERIAL, WHOLESALER],
                },
            },
            {
                path: 'souscripteurs/:id',
                name: 'souscripteursForm',
                component: () => import('@views/subscriber/SubscriberForm.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, 'ROLE_BROKER,', WHOLESALER],
                },
            },
            {
                path: 'user/current',
                name: 'userCurrentForm',
                component: () => import('@views/subscriber/SubscriberForm.vue'),
                meta: {
                    authRequired: true,
                    currentUser: true,
                },
            },
            {
                path: 'devis',
                name: 'quoteList',
                component: () => import('@views/quote/QuoteList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER, WHOLESALER, BROKER],
                },
            },
            {
                path: 'quote-price-study',
                name: 'quotePriceStudyList',
                component: () => import('@views/quote/QuotePriceStudyList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                    products: ['EQ'],
                },
            },
            {
                path: 'devis/create/',
                name: 'quoteCreate',
                component: () => import('@views/quote/QuoteCreationWrap.vue'),
                meta: {
                    authRequired: true,
                    isCreation: true,
                    currentCompanyIsNotDisabled: true,
                    roles: [BROKER],
                },
                children: [
                    {
                        path: 'product-select',
                        name: 'quoteProductSelector',
                        component: () => import('@views/quote/QuoteProductSelector.vue'),
                        meta: {
                            authRequired: true,
                            isCreation: true,
                            currentCompanyIsNotDisabled: true,
                            roles: [BROKER],
                        },
                    },
                    negoAutoQuoteRoutes,
                    equiteQuoteRoutes,
                ],
            },
            // {
            //     path: '/cdb',
            //     name: 'cdb',
            //     component: () => import('@views/public/Cdb.vue'), //<-- La page que tu vas créer
            //     // Allows props to be passed to the 404 page through route
            //     // params, such as `resource` to define what wasn't found.
            //     props: true,
            // },
            {
                path: 'quote-result/:id',
                name: 'quoteResultForm',
                component: () => import('@views/quote/AbstractQuoteForm.vue'),
                meta: {
                    authRequired: true,
                    noBack: true,
                    roles: [AERIAL, WHOLESALER, BROKER],
                },
            },
            {
                path: 'devis/:id',
                name: 'quoteForm',
                component: () => import('@views/quote/AbstractQuoteForm.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER, WHOLESALER, BROKER],
                },
            },
            {
                path: 'customers/:customerId/garage/:id',
                name: 'garageForm',
                component: () => import('@views/garage/GarageForm.vue'),
                meta: {
                    authRequired: true,
                    isCreation: false,
                },
            },
            {
                path: 'contracts',
                name: 'contractList',
                component: () => import('@views/contract/ContractList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'contracts/:id',
                name: 'contractForm',
                component: () => import('@views/contract/NewContractForm.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'contracts/:id/signature',
                name: 'contractSignature',
                component: () => import('@views/contract/ContractSignature.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                    roles: [AERIAL, BROKER, CELLINKS],
                },
            },
            {
                path: 'contracts/:id/signature/sepa/:bankAccountUsageId',
                name: 'contractSEPASignature',
                component: () => import('@views/contract/ContractSEPASignature.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                },
            },
            {
                path: 'contracts/:id/paiement',
                name: 'contractPaiement',
                component: () => import('@views/contract/ContractPaiement.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                    roles: [AERIAL, BROKER],
                },
            },
            {
                path: 'contracts/:id/changeSepa',
                name: 'contractChangeSepa',
                component: () => import('@views/contract/ContractChangeSepa.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                },
            },
            {
                path: 'contracts-terminations',
                name: 'contractTerminationList',
                component: () => import('@views/contractTermination/ContractTerminationList.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER],
                    products: ['EQ'],
                },
            },
            {
                path: 'contracts-terminations/:id',
                name: 'contractTerminationForm',
                component: () => import('@views/contractTermination/ContractTerminationForm.vue'),
                meta: {
                    authRequired: true,
                    currentCompanyIsNotDisabled: true,
                },
            },
            {
                path: 'customers',
                name: 'customerList',
                component: () => import('@views/customer/CustomerList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'customers/:id',
                name: 'customerForm',
                component: () => import('@views/customer/AbstractCustomerForm.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'sinisters',
                name: 'sinisterList',
                component: () => import('@views/sinister/SinisterList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER],
                    products: ['EQ'],
                },
            },
            {
                path: 'sinisters/:id',
                name: 'sinisterForm',
                component: () => import('@views/sinister/SinisterForm.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'company-export',
                name: 'companyExport',
                component: () => import('@views/companyExport/companyExportList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'documents',
                name: 'documentList',
                component: () => import('@views/document/documentList.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'financial',
                name: 'financialDashboard',
                component: () => import('@views/dashboard/FinancialDashboard.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, WHOLESALER],
                },
            },
            {
                path: 'transactions',
                name: 'transactionList',
                component: () => import('@views/dashboard/TransactionList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'messages',
                name: 'messagesList',
                component: () => import('@views/messages/MessagesList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'products',
                name: 'productList',
                component: () => import('@views/products/ProductList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'products/:id',
                name: 'productForm',
                component: () => import('@views/products/ProductForm.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'messages/:id',
                name: 'messageForm',
                component: () => import('@views/messages/MessageForm.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'messages/create',
                name: 'messagesCreate',
                component: () => import('@views/messages/MessageForm.vue'),
                meta: {
                    authRequired: true,
                    isCreation: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'transactions',
                name: 'transactionList',
                component: () => import('@views/dashboard/TransactionList.vue'),
                meta: {
                    authRequired: true,
                    roles: ['ROLE_AERIAL'],
                },
            },
            {
                path: 'repayments',
                name: 'repaymentList',
                component: () => import('@views/financial/RepaymentList.vue'),
                meta: {
                    authRequired: true,
                    roles: ['ROLE_AERIAL'],
                },
            },
            {
                path: 'unpaids',
                name: 'unpaidList',
                component: () => import('@views/financial/UnpaidList.vue'),
                meta: {
                    authRequired: true,
                    roles: ['ROLE_AERIAL', 'ROLE_CELLINKS', 'ROLE_CELLINKS_READER'],
                },
            },
            {
                path: 'mentions-legales/',
                name: 'legalesMentions',
                component: () => import('@views/LegalMention.vue'),
                meta: {
                    authRequired: true,
                },
            },
            {
                path: 'unauthorized',
                name: 'unauthorized',
                component: () => import('@views/core/_unauthorized.vue'),
                // Allows props to be passed to the 404 page through route
                // params, such as `resource` to define what wasn't found.
                props: true,
            },
            {
                path: 'administration',
                name: 'administration',
                component: () => import('@views/administration/Administration.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL],
                },
            },
            {
                path: 'provisional-subscriptions',
                name: 'provisionalSubscriptionList',
                component: () => import('@views/provisionalSubscription/provisionalSubscriptionList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER, BROKER, WHOLESALER],
                    products: ['EQ'],
                },
            },
            {
                path: 'provisional-amendment',
                name: 'provisionalAmendmentList',
                component: () => import('@views/provisionalSubscription/AmendmentSubscriptionList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER, BROKER, WHOLESALER],
                    products: ['EQ'],
                },
            },
            {
                path: 'reminder-subscriptions',
                name: 'reminderSubscriptionList',
                component: () => import('@views/subscriptionReminder/SubscriptionReminderList.vue'),
                meta: {
                    authRequired: true,
                    roles: [AERIAL, CELLINKS, CELLINKS_READER],
                    products: ['EQ'],
                },
            },
            {
                path: 'contracts/:id/accompanied-driver',
                name: 'addAccompaniedDriver',
                component: () => import('@views/contract/equite/addAccompaniedDriver.vue'),
                meta: {
                    authRequired: true,
                    roles: [CELLINKS, BROKER, WHOLESALER],
                    products: ['EQ'],
                },
            },
            {
                path: 'contracts/:id/temporary-extended-warranty',
                name: 'temporaryExtendedWarranty',
                component: () => import('@views/contract/equite/temporaryExtendedWarranty.vue'),
                meta: {
                    authRequired: true,
                    roles: [CELLINKS, BROKER, WHOLESALER],
                    products: ['EQ'],
                },
            },
        ],
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@views/core/_404.vue'),
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    {
        path: '/signature-result',
        name: 'signature-result',
        component: () => import('@views/public/SignatureResult.vue'),
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '404',
    },
];

// Sandbox actif uniquement si pas en prod !
if ('prod' !== process.env.VUE_APP_ENVIRONMENT) {
    const files = require.context('@views/sandbox', true, /\.vue$/i);
    files.keys().map((key) => {
        const fileName = key.split('/').pop().split('.')[0];
        routes.push({
            path: '/' + fileName,
            name: fileName,
            component: () => import('@views/sandbox/' + fileName + '.vue'),
        });
    });
}

export default routes;
