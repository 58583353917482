<script>
  import backMixin from '@mixins/backMixin';
  import messageMixin from '@mixins/messageMixin';
  import loaderMixin from '@mixins/loaderMixin';

  export default {
    mixins: [backMixin, messageMixin, loaderMixin],
    props: {title: String, showBack: {type: Boolean, default: true}, backFunction: {type: Function}, publicPage: {type: Boolean, default: false}
      ,tooltip: {type: String, required:false}
    , tabs:{type:Array}},
    data() {
      return {
        failToLoad: false,
        displayTab:{},
        displaySubTab:{}
      };
    },
    methods: {
      setLoadingFailed(p_sMessage) {
        this.failToLoad = true;
        if (null != p_sMessage) this.error(p_sMessage);
      },
      display(tab) {
        this.displayTab = tab;
        if(null != this.displayTab?.subTabs){
          this.displaySubTab =  this.displayTab?.subTabs[0]
        }else{
          this.displaySubTab = {}
        }
      },
      displaySub(subTab){
        this.displaySubTab = subTab;
      }
    },
    computed: {
      _goBack() {
        if (null != this.backFunction) return this.backFunction;
        else return this.goBack;
      },
      subTabs(){
        return this?.displayTab?.subTabs
      }
      
    },
    mounted() {
      if(null != this.tabs){
        if (this.firstTab) {
        for (let t of this.tabs) {
          if (t.id === this.firstTab) {
            this.displayTab = t;
          }
        }
      } else {
        this.displayTab = this.tabs[0];
      }

      if(null != this.displayTab?.subTabs){
        this.displaySubTab =  this.displayTab?.subTabs[0]
      }
      }
      
    },
  };
</script>

<template>
  <div class="ak-form-view">
    <a class="mb-1  pointer" @click="_goBack()" v-if="showBack"
      ><i class="fe fe-arrow-left pr-1"></i>{{ $t('back') }}</a
    >
    <Message
      v-for="msg of messages"
      :key="msg.content"
      :closable="msg.closable"
      :severity="msg.severity"
      :sticky="true"
      :class="'main-container-sticky'">
      {{ msg.content }}
    </Message>
    <div class="card card-statistics" v-if="!failToLoad">
      
      <template v-if="null != tabs">
        <div class="tab nav-border-bottom">
        <ul class="nav nav-tabs" role="tablist">
          <template v-for="tab of this.tabs" :key="tab">
            <li class="nav-item pointer" @click="display(tab)">
                            <span class="show nav-link" :class="{active: tab.id === displayTab.id}">
                                <font-awesome-icon :icon="['fas', tab.icon]" fixed-width/>
                                {{ tab.name }}
                            </span>
            </li>
          </template>
        </ul>
      </div>

      </template>
      <template v-else>
        <slot name="tabs"></slot>
      </template>
      <template v-if="null != subTabs">
        <div class="sub-tabs">
        <ul
          class="nav nav-tabs mb-1 tab-list  d-flex flex-wrap"
          role="tablist"
      >
        <template
            v-for="tab of subTabs"
            :key="tab.id"
        >
          <li
              v-if="!tab.hidden"
              :class="{'active':this.displaySubTab.id === tab.id}" class="nav-item d-flex justify-content-center p-ripple  pt-1 pb-1"  v-ripple
              @click="displaySub(tab)"
          >
          <font-awesome-icon v-if="null != tab.icon" class="mr-1" :icon="tab.icon" />
            {{ tab.name }}
          </li>
        </template>
      </ul>
    </div>
      </template>
      <template v-else>
        <slot name="subTabs"></slot>
      </template>
      
      <div :class="'card-header sticky-card-header'">
        <div class="card-heading">
          <div class="row align-items-center">
            <div class="col-md-12 d-flex">
              <h5 class="card-title">{{ title }}</h5>
             <i v-if="tooltip" class="fa fa-info-circle tooltip-centered" v-tooltip="tooltip"/>
              <div class="text-md-right text-center ml-auto">
                  <slot name="action"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body vld-parent">
        <template v-if="null != tabs">
          <template v-for="tab in tabs" :key="tab.id">
            <template v-if="null != subTabs">
              <template v-for="subTab in subTabs" :key="subTab.id">
                <slot :name="tab.id + '__' + subTab.id"  v-if="displayTab.id===tab.id && displaySubTab.id == subTab.id"/>
              </template>
            </template>
            <template v-else>
              <keep-alive>
              <slot :name="tab.id"  v-if="displayTab.id===tab.id" :subTab="displaySubTab"/>
            </keep-alive>
            </template>
            
        </template>
        </template>
        <template v-else>
          <slot name="form" />
        </template>
      </div>
    </div>
    <div v-else> </div>
  </div>
</template>
