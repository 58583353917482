<script>
import AkLabel from '@components/general/AkLabel';

export default {
    components: {AkLabel},
    inject: {
        pSubmitted: {from: 'pSubmitted', default: null},
        pDisabled: {from: 'pDisabled', default: null},
    },
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {},
        placeholder: {
            type: String,
            required: false,
        },
        className: {
            type: String,
            required: false,
            default: 'col-md-4',
        },
        validator: {
            type: Object,
            required: false,
        },
        submitted: {
            type: Boolean,
            required: false,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: undefined,
        },
        type: {
            type: String,
            default: 'text',
        },
        format: {
            type: Function,
            default: undefined,
        },
    },
    computed: {
        currentValue: {
            get: function () {
                return this.modelValue;
            },
            set: function (val) {
                if (this.format !== undefined) {
                    const formatedVal = this.format(val);

                    if (val != formatedVal) {                        
                        const input = this.$el.querySelector('input');
                        const cursorPos = input.selectionStart;

                        val = formatedVal;
                        setTimeout(() => {
                            input.setSelectionRange(cursorPos, cursorPos);
                        }, 0);
                    }
                }
                
                this.$emit('update:modelValue', val);
            },
        },
        getPlaceholder() {
            return this.placeholder ? this.placeholder : this.label;
        },
        alreadySubmitted() {
            if (this.submitted !== undefined) return this.submitted;
            if (this.pSubmitted !== undefined) return this.pSubmitted;
            return false;
        },
        isDisabled() {
            if (this.disabled !== undefined) return this.disabled;
            if (this.pDisabled !== undefined) return this.pDisabled;
            return false;
        },
        hasValidator() {
            return this.validator !== undefined;
        },
        isInvalid() {
            return this.validator?.$invalid;
        },
        isRequired() {
            return this.validator?.required;
        },
        isValidatorRequired() {
            return this.validator?.required?.$invalid && this.alreadySubmitted;
        },
        isValidatorMinMaxLength() {
            return (
                this.validator !== undefined &&
                (this.validator.minLength !== undefined || this.validator.maxLength !== undefined) &&
                (this.validator.minLength.$invalid || this.validator.maxLength.$invalid) &&
                this.alreadySubmitted
            );
        },
        isValidatorNumeric() {
            return this.validator?.numeric?.$invalid && this.alreadySubmitted;
        },
    },
};
</script>

<template>
    <div :class="this.className" class="form-group">
        <ak-label :required="isRequired">{{ this.label }}</ak-label>
        <InputText
            v-model="currentValue"
            :class="{'p-invalid': isInvalid && alreadySubmitted}"
            :disabled="isDisabled"
            :placeholder="getPlaceholder"
            class="form-control"
            :type="type" />

        <template v-if="validator && alreadySubmitted">
            <small v-for="error of validator.$errors" :key="error.$uid" class="p-error">
                {{ $t(error.$message) }}
            </small>
        </template>

  </div>
</template>
