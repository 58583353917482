<script>
export default {
    props: {
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        id: {
            type: String,
            required: false,
        },
    },
    methods: {},
  computed: {
    getRequired() {
      return this.required ? ' *' : '';
    },
  },
};
</script>

<template>
    <label :for="id"><slot/>{{ getRequired }}</label>
</template>