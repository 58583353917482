import ProductService from '@services/productService';

export default {
    namespaced: true,
    state: {
        products: null,
    },
    mutations: {
        SET_PRODUCTS(state, newValue) {
            state.products = newValue;
        },
    },
    getters: {
        products(state) {
            return state.products;
        },
    },
    actions: {
        // This is automatically run in `src/state/store.js` when the app
        // starts, along with any other actions named `init` in other modules.

        setProductList({commit}, {productList}) {
            commit('SET_PRODUCTS', productList, {root: false});
        },
        async list({commit}) {
            ProductService.list().then((data) => {
                commit('SET_PRODUCTS', data, {root: false});
            });
        },
    },
    modules: {},
};
