import Api from '@services/api';
//import contentDisposition from '@utils/ak-content-disposition';
function extractFileName(contentDisposition) {
    if (!contentDisposition) return;
    let regexp = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
    var matches = regexp.exec(contentDisposition);
    if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
    }
    return;
}
export function downloadResponse(response, fileName) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    if (fileName == undefined) {
        fileName = extractFileName(response.headers['content-disposition']);
    }
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    return;
}
export function downloadUtils(url, fileHash, fileName, preview) {
    return Api()
        .get(url, {
            params: {md5: fileHash},
            responseType: 'blob',
        })
        .then((response) => {
            if (!preview) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (fileName == undefined) {
                    //fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
                    fileName = extractFileName(response.headers['content-disposition']);
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                return;
            }
            return response.data;
        })
}

export function downloadUtilsDates(url, dates, fileHash, fileName, preview) {
    return Api()
        .get(url, {
            params: {md5: fileHash, start: dates.start, end: dates.end},
            responseType: 'blob',
        })
        .then((response) => {
            if (!preview) {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if (fileName == undefined) {
                    // fileName = contentDisposition.parse(response.headers['content-disposition']).parameters.filename;
                    fileName = extractFileName(response.headers['content-disposition']);
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                return;
            }
            return response.data;
        })
}
