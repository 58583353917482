import {AERIAL, BROKER, WHOLESALER, CELLINKS} from '@utils/roles';

// définition des "codes" de fonctions (utile pour import dans les composants VS réécriture de la string en dur)
export const validate_contract_document = 'validate_contract_document';
export const validate_contract_equite = 'validate_contract_equite';
export const validate_contract_termination = 'validate_contract_termination';
export const edit_contract_document = 'edit_contract_document';
export const validate_sinister = 'validate_sinister';
export const transform_quote_to_contract = 'transform_quote_to_contract';
export const create_quote = 'create_quote';
export const edit_logo = 'edit_logo';
export const affect_provisionnal_subscription = 'affect_provisionnal_subscription';
export const mark_transaction_as_paid = 'mark_transaction_as_paid';
export const acls = {};

// liste des roles exporter
// Deux formats disponible :
// -> directement le tableau des rôles autorisés pour le role.
// -> Un objet contenant deux propriétés :
//      - roles : la liste des roles autorisés
//      - onlyActiveAccount : si true : la fonction sera refusé s'il s'agit d'un courtier désactivé.

acls[validate_sinister] = [CELLINKS];
acls[validate_contract_document] = [CELLINKS];
acls[edit_contract_document] = [AERIAL, CELLINKS];
acls[edit_logo] = [AERIAL, WHOLESALER];
acls[create_quote] = [BROKER, WHOLESALER];
acls[transform_quote_to_contract] = {roles: [BROKER, WHOLESALER], onlyActiveAccount: true};
acls[validate_contract_equite] = [CELLINKS];
acls[affect_provisionnal_subscription] = [CELLINKS];
acls[validate_contract_termination] = [CELLINKS];
acls[mark_transaction_as_paid] = [AERIAL];
