import Api from "@/services/api";

export default {
    login(user) {
        return Api(false).post("/token", user).then(res => res.data);
    },
    refresh(refreshtoken) {
        return Api(false).post("/refreshToken", refreshtoken).then(res => res.data);
    },
}
